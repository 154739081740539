import * as React from "react"
import PageLayout from "../../components/pageLayout";
import useOrdersApi from "../../services/orders/useOrdersApi"
import {Table, Pagination, Col, Row , Button , Modal,notification,  Select, Space} from "antd"
import {PlusOutlined, PrinterOutlined, SyncOutlined} from "@ant-design/icons"
import {Link} from "gatsby"
import Column from 'antd/lib/table/Column';

const {Option} = Select 

const apiUri  = "https://price-connect.conceptos.shop/api"
//const apiUri  = "http://localhost:3000/api"
const OrdersPage = () => {

    
  const [query, setQuery] =React.useState({})
  const [page, setPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)
  const [status, setStatus] = React.useState("pendiente")

  const [selectedStore, setSelectedStore] = React.useState("all")
  
  const {count, orders} = useOrdersApi(query, page, limit, status)


  console.log(orders)

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [loadingPlan, setIsLoadingPlan] = React.useState(false)
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {

          let requestBody = {            
            store: selectedStore
          }
          setIsLoadingPlan(true)
          fetch(`${apiUri}/orders/plan`, 
          {
            method: "POST",            
            body: JSON.stringify(requestBody),
            headers: {
              "Content-Type":"application/json"
            }
          }).then((response)=>{
             response.blob().then(blob=>{
                 let file = window.URL.createObjectURL(blob)
                 window.location.assign(file)
             })
          }).catch(error=> {
            console.error(error)
          }).finally(()=>{
            setIsModalVisible(false);
            setIsLoadingPlan(false)
          })

    
  };

  const changeOrderStatus = (orderId, status) => {


       let  order = orders.find(x => x._id === orderId)
       order.status = status
       fetch(`${apiUri}/orders`, 
          {
            method: "PUT",            
            body: JSON.stringify(order),
            headers: {
              "Content-Type":"application/json"
            }
          }).then((response)=>{
             
            if(response.status === 200 || response.status === 204)
              notification.success({
                key: orderId, 
                message: "Orden actualizada",
                description: `La orden cambio a estatus ${status}`
              })                
             
          }).catch(error=> {
            console.error(error)
          }).finally(()=>{
            
          })

  }

  const updateOrder = (orderId, handler) => {


    let  order = orders.find(x => x._id === orderId)        
    fetch(`${apiUri}/orders`, 
       {
         method: "POST",            
         body: JSON.stringify({
           origin: order.customer,
           orderId: order.orderId,
           sku: order.sku, 
           product: order.product
         }),
         headers: {
           "Content-Type":"application/json"
         }
       }).then((response)=>{
          
        if(response.status === 200 || response.status === 204)
        notification.success({
          key: orderId, 
          message: "Orden actualizada",
          description: `Se descargo la información de la orden ${handler}`
        })      
          
       }).catch(error=> {
         console.error(error)
       }).finally(()=>{
         
       })

}


  
  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const   onPaginationChange =  (newPage, size ) => {
    console.log(`New page ${newPage}`)
    console.log(`old limit ${limit} new limit:${size}`)
    if(page  !== newPage - 1)
      setPage(newPage-1)
    if(limit !== size)
      setLimit(size) 
  }

  const renderStoreStock = (storeName, record) => {

    let store = record.locations.find(x=> x.name === storeName)
    if(store) {
        return <span>{store.stock}</span>
    }
    else 
        return <span>0</span>
  }

return <PageLayout>


        <Space direction="vertical" style={{width:"100%"}}>
            <Row gutter={16} justify="space-between">
                <Col span={8}>
                  <Select value={status} onChange={(value)=>{ setStatus(value)}}>
                      <Option value="pendiente">Pendientes</Option>
                      <Option value="preparado">Preparadas</Option>
                      <Option value="cancelado">Canceladas</Option>
                      <Option value={undefined}>Todas</Option>
                  </Select></Col>            
                <Col span={8}>
                  <Space>
                      <Button type="primary" onClick={showModal} icon={<PrinterOutlined/>}>Imprimir orden de trabajo</Button>
                      <Button type="primary" ><Link to="/orders/new">Nueva orden</Link></Button>
                  </Space>
                </Col>                 
            </Row>


            <Table size="middle" dataSource={orders} pagination={{ pageSize:limit , position:["none", "none"]}}>
                <Column title="N° Pedido" dataIndex="orderId"></Column>
                <Column title="SKU" dataIndex="sku"></Column>
                <Column title="Marca" dataIndex="marca"></Column>
                <Column title="Modelo" dataIndex="modelo"></Column>
                <Column title="Talla" dataIndex="talla"></Column>
                <Column title="Precio" dataIndex="precio"></Column>
                <Column title="Arco"  dataIndex={["locations",8, "stock"]} render={(text,record)=> renderStoreStock("Arco Norte", record)}></Column>
                <Column title="Outlet Arco" dataIndex={["locations",5, "stock"]} render={(text,record)=> renderStoreStock("Outlet Arco Norte", record)}></Column>
                <Column title="Ecatepec" dataIndex={["locations", 7, "stock"]} render={(text,record)=> renderStoreStock("Ecatepec", record)}></Column>
                <Column title="Outlet Ecatepec" dataIndex={["locations",6, "stock"]} render={(text,record)=> renderStoreStock("Outlet Ecatepec", record)}></Column>
                <Column title="Vallejo" dataIndex={["locations",0, "stock"]} render={(text,record)=> renderStoreStock("Vallejo", record)}></Column>
                <Column title="Naucalpan" dataIndex={["locations",1, "stock"]} render={(text,record)=> renderStoreStock("Naucalpan", record)}></Column>
                <Column title="Centro" dataIndex={["locations",3, "stock"]} render={(text,record)=> renderStoreStock("Centro Histórico", record)}></Column>
                <Column title="Olivar del Conde" dataIndex={["locations",2, "stock"]} render={(text,record)=> renderStoreStock("Olivar del Conde", record)}></Column>
                <Column title="Iztapalapa" dataIndex={["locations",4, "stock"]} render={(text,record)=> renderStoreStock("Iztapalapa", record)}></Column>
                <Column title="Estatus" dataIndex="status"></Column>                        
                <Column title="" dataIndex="_id" render={(id, record)=>                      
                <div>
                  {
                    record.status === "pendiente" ?
                    <Space>
                        <Button onClick={()=> updateOrder(id,record.orderId)}><SyncOutlined/></Button>
                        <Button onClick={()=> changeOrderStatus(id, "preparado")} type="primary" success>Preparar</Button>
                        <Button onClick={()=> changeOrderStatus(id, "cancelado")} type="primary" danger>Cancelar</Button>
                    </Space>: 
                    <span>{record.status}</span>

                  }              
                </div>                        
                }></Column>
            </Table>
            <Pagination total={count} onChange={onPaginationChange} showSizeChanger ></Pagination>
        </Space>
        


        <Modal title="Generar orden de trabajo" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} okButtonProps={{
            loading: loadingPlan
        }}>
             
             <Row>
                <label><b>Selecciona una tienda</b></label>
                <br></br>
             </Row>
             <Row>                                  
                 <Select value={selectedStore}  onChange={value=> setSelectedStore(value)} >
                    <Option value="all">Todas las tiendas</Option>
                    <Option value="arco-norte">Arco Norte</Option>
                    <Option value="outlet-arco-norte">Outlet Arco Norte</Option>
                    <Option value="ecatepec">Ecatepec</Option>
                    <Option value="outlet-ecatepec">Outlet Ecatepec</Option>
                    <Option value="naucalpan">Naucalpan</Option>
                    <Option value="vallejo">Vallejo</Option>
                    <Option value="olivar-del-conde">Olivar del Conde</Option>
                    <Option value="centro-historico">Centro Historico</Option>
                    <Option value="iztapalapa">Iztapalapa</Option>
                 </Select>
             </Row>             
        </Modal>

        
    </PageLayout>

}

export default OrdersPage