import React from "react";

import ordersApi from "./index"

const useOrdersApi = (query, page, limit , status) => {    
    const [count, setCount] = React.useState(0)
    const [orders, setOrders] = React.useState([])  
    const [orderFilter, setOrderFilter] = React.useState(undefined)
    const [error, setError] = React.useState("")    
    
    const [isLoading, setIsLoading] = React.useState(false)
    
    const saveOrder = (order) => {
        setIsLoading(true)
        ordersApi().save(order).then(response=> {
            if(response.status === 200)
            {
                setOrders([...orders, response.data])
            }
            setIsLoading(false)
        }).catch(error=> {
            setIsLoading(false)
        })
    }
    const getOrders = (query, page, limit) => {
    }
    const remove  = async(id) => {

    }

   
    

    React.useEffect(()=>{
        console.log(`query product hook executed on page ${page} limit ${limit}` )
        ordersApi().count(orderFilter, status ).then(response =>{
            if(response.status === 200)
            {
                setCount(response.data.count)
            }
          }).catch(error=> {})

        ordersApi().get(query,{ page, limit},  status).then(response => {
            if(response.status === 200)
            {
                setOrders(response.data)
            }
            
                        
        }).catch(error=> {

        })
    },[page,limit,query, status])


    return {
        count, 
        page,
        limit,    
        orders,
        error,         
        isLoading,
        remove,                    
        getOrders
    }


}

export default useOrdersApi;